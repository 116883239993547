




























































































































































































































































































































































































































































.dottie-app {
  font-family: arial, sans-serif;
  background-color: #212121;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
#help {
  position: absolute;
  color: #38c;
  top: 0;
  right: 0;
  padding: 20px;
  text-shadow: 0 0 1px #3388cc, 0 0 5px rgba(51, 136, 204, 0.75), 0 0 10px rgba(51, 136, 204, 0.5), 0 0 15px rgba(51, 136, 204, 0.25);
}
.links {
  position: fixed;
  display: flex;
  flex-direction: column;
  bottom: 12px;
  right: 12px;
  text-align: right;
  opacity: 0.25;
  transition: opacity 1s ease-in-out;
}
.links:hover {
  opacity: 0.9;
}
.links a {
  color: #3da;
  font-family: monospace;
  text-decoration: none;
}
.links a:hover {
  color: #38c;
}
.matrix {
  display: flex;
  flex-direction: column;
}
.matrix-row {
  height: 40px;
  width: 480px;
  display: flex;
  flex-direction: row;
}
.matrix-cell {
  width: 40px;
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.dottie-dot-1 {
  width: 40px;
  height: 5px;
  background-color: #38c;
  font-size: 0;
  border-radius: 5px;
  box-shadow: 0 0 1px #3388cc, 0 0 5px rgba(51, 136, 204, 0.75), 0 0 10px rgba(51, 136, 204, 0.5), 0 0 15px rgba(51, 136, 204, 0.25);
  animation: fade-in 0.5s ease-in;
  animation-fill-mode: both;
  transform: rotate(45deg) scale(1.3);
}
.dottie-dot-2 {
  width: 40px;
  height: 5px;
  background-color: #38c;
  font-size: 0;
  border-radius: 5px;
  box-shadow: 0 0 1px #3388cc, 0 0 5px rgba(51, 136, 204, 0.75), 0 0 10px rgba(51, 136, 204, 0.5), 0 0 15px rgba(51, 136, 204, 0.25);
  animation: fade-in 0.5s ease-in;
  animation-fill-mode: both;
  transform: rotate(-45deg) scale(1.3);
}
.dottie-dot-3 {
  width: 40px;
  height: 5px;
  background-color: #38c;
  font-size: 0;
  border-radius: 5px;
  box-shadow: 0 0 1px #3388cc, 0 0 5px rgba(51, 136, 204, 0.75), 0 0 10px rgba(51, 136, 204, 0.5), 0 0 15px rgba(51, 136, 204, 0.25);
  animation: fade-in 0.5s ease-in;
  animation-fill-mode: both;
  width: 5px;
}
.dottie-dot-11 {
  width: 40px;
  height: 5px;
  background-color: #3bc;
  font-size: 0;
  border-radius: 5px;
  box-shadow: 0 0 1px #33bbcc, 0 0 5px rgba(51, 187, 204, 0.75), 0 0 10px rgba(51, 187, 204, 0.5), 0 0 15px rgba(51, 187, 204, 0.25);
  animation: fade-in 0.5s ease-in;
  animation-fill-mode: both;
  transform: rotate(45deg) scale(1.3);
}
.dottie-dot-12 {
  width: 40px;
  height: 5px;
  background-color: #3bc;
  font-size: 0;
  border-radius: 5px;
  box-shadow: 0 0 1px #33bbcc, 0 0 5px rgba(51, 187, 204, 0.75), 0 0 10px rgba(51, 187, 204, 0.5), 0 0 15px rgba(51, 187, 204, 0.25);
  animation: fade-in 0.5s ease-in;
  animation-fill-mode: both;
  transform: rotate(-45deg) scale(1.3);
}
.dottie-dot-13 {
  width: 40px;
  height: 5px;
  background-color: #3bc;
  font-size: 0;
  border-radius: 5px;
  box-shadow: 0 0 1px #33bbcc, 0 0 5px rgba(51, 187, 204, 0.75), 0 0 10px rgba(51, 187, 204, 0.5), 0 0 15px rgba(51, 187, 204, 0.25);
  animation: fade-in 0.5s ease-in;
  animation-fill-mode: both;
  width: 5px;
}
.dottie-dot-21 {
  width: 40px;
  height: 5px;
  background-color: #3da;
  font-size: 0;
  border-radius: 5px;
  box-shadow: 0 0 1px #33ddaa, 0 0 5px rgba(51, 221, 170, 0.75), 0 0 10px rgba(51, 221, 170, 0.5), 0 0 15px rgba(51, 221, 170, 0.25);
  animation: fade-in 0.5s ease-in;
  animation-fill-mode: both;
  transform: rotate(45deg) scale(1.3);
}
.dottie-dot-22 {
  width: 40px;
  height: 5px;
  background-color: #3da;
  font-size: 0;
  border-radius: 5px;
  box-shadow: 0 0 1px #33ddaa, 0 0 5px rgba(51, 221, 170, 0.75), 0 0 10px rgba(51, 221, 170, 0.5), 0 0 15px rgba(51, 221, 170, 0.25);
  animation: fade-in 0.5s ease-in;
  animation-fill-mode: both;
  transform: rotate(-45deg) scale(1.3);
}
.dottie-dot-23 {
  width: 40px;
  height: 5px;
  background-color: #3da;
  font-size: 0;
  border-radius: 5px;
  box-shadow: 0 0 1px #33ddaa, 0 0 5px rgba(51, 221, 170, 0.75), 0 0 10px rgba(51, 221, 170, 0.5), 0 0 15px rgba(51, 221, 170, 0.25);
  animation: fade-in 0.5s ease-in;
  animation-fill-mode: both;
  width: 5px;
}
.dottie-dot-31 {
  width: 40px;
  height: 5px;
  background-color: #E42;
  font-size: 0;
  border-radius: 5px;
  box-shadow: 0 0 1px #ee4422, 0 0 5px rgba(238, 68, 34, 0.75), 0 0 10px rgba(238, 68, 34, 0.5), 0 0 15px rgba(238, 68, 34, 0.25);
  animation: fade-in 0.5s ease-in;
  animation-fill-mode: both;
  transform: rotate(45deg) scale(1.3);
}
.dottie-dot-32 {
  width: 40px;
  height: 5px;
  background-color: #E42;
  font-size: 0;
  border-radius: 5px;
  box-shadow: 0 0 1px #ee4422, 0 0 5px rgba(238, 68, 34, 0.75), 0 0 10px rgba(238, 68, 34, 0.5), 0 0 15px rgba(238, 68, 34, 0.25);
  animation: fade-in 0.5s ease-in;
  animation-fill-mode: both;
  transform: rotate(-45deg) scale(1.3);
}
.dottie-dot-33 {
  width: 40px;
  height: 5px;
  background-color: #E42;
  font-size: 0;
  border-radius: 5px;
  box-shadow: 0 0 1px #ee4422, 0 0 5px rgba(238, 68, 34, 0.75), 0 0 10px rgba(238, 68, 34, 0.5), 0 0 15px rgba(238, 68, 34, 0.25);
  animation: fade-in 0.5s ease-in;
  animation-fill-mode: both;
  width: 5px;
}
.dottie-dot--1 {
  width: 40px;
  height: 5px;
  background-color: #292929;
  font-size: 0;
  border-radius: 5px;
  box-shadow: 0 0 1px #292929, 0 0 5px rgba(41, 41, 41, 0.75), 0 0 10px rgba(41, 41, 41, 0.5), 0 0 15px rgba(41, 41, 41, 0.25);
  animation: fade-in 0.5s ease-in;
  animation-fill-mode: both;
  transform: rotate(45deg) scale(1.3);
}
.dottie-dot--2 {
  width: 40px;
  height: 5px;
  background-color: #292929;
  font-size: 0;
  border-radius: 5px;
  box-shadow: 0 0 1px #292929, 0 0 5px rgba(41, 41, 41, 0.75), 0 0 10px rgba(41, 41, 41, 0.5), 0 0 15px rgba(41, 41, 41, 0.25);
  animation: fade-in 0.5s ease-in;
  animation-fill-mode: both;
  transform: rotate(-45deg) scale(1.3);
}
.dottie-dot-cross {
  width: 40px;
  height: 5px;
  background-color: #38c;
  font-size: 0;
  border-radius: 5px;
  box-shadow: 0 0 1px #3388cc, 0 0 5px rgba(51, 136, 204, 0.75), 0 0 10px rgba(51, 136, 204, 0.5), 0 0 15px rgba(51, 136, 204, 0.25);
  animation: fade-in 0.5s ease-in;
  animation-fill-mode: both;
  transform: rotate(45deg) scale(1.3);
}
.dottie-dot-cross:after {
  width: 40px;
  height: 5px;
  background-color: #38c;
  font-size: 0;
  border-radius: 5px;
  box-shadow: 0 0 1px #3388cc, 0 0 5px rgba(51, 136, 204, 0.75), 0 0 10px rgba(51, 136, 204, 0.5), 0 0 15px rgba(51, 136, 204, 0.25);
  animation: fade-in 0.5s ease-in;
  animation-fill-mode: both;
  content: "";
  position: absolute;
  transform: rotate(90deg);
}
.animated-1 {
  animation: blink 1s infinite;
}
.animated-2 {
  opacity: 1;
  animation-name: neon-start;
  animation-duration: 5s;
  animation-delay: 0s;
  animation-iteration-count: 1;
}
.animated-3 {
  animation-name: slow-fade;
  animation-duration: 15s;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-fill-mode: both;
}
.animated-4 {
  animation: defect 8s infinite;
}
.opacity-100 {
  opacity: 1;
}
.opacity-90 {
  opacity: 0.9;
}
.opacity-80 {
  opacity: 0.8;
}
.opacity-70 {
  opacity: 0.7;
}
.opacity-60 {
  opacity: 0.6;
}
.opacity-50 {
  opacity: 0.5;
}
.opacity-40 {
  opacity: 0.4;
}
.opacity-30 {
  opacity: 0.3;
}
.opacity-20 {
  opacity: 0.2;
}
.opacity-10 {
  opacity: 0.1;
}
.opacity-0 {
  opacity: 0.05;
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes blink {
  0% {
    opacity: 0;
  }
  15% {
    opacity: 1;
  }
  85% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes neon-start {
  0% {
    opacity: 0;
  }
  66.9% {
    opacity: 0;
  }
  67% {
    opacity: 1;
  }
  72% {
    opacity: 1;
  }
  72.1% {
    opacity: 0;
  }
  76.9% {
    opacity: 0;
  }
  77% {
    opacity: 1;
  }
  78.9% {
    opacity: 1;
  }
  79% {
    opacity: 0;
  }
  82% {
    opacity: 0;
  }
  83% {
    opacity: 1;
  }
  86% {
    opacity: 1;
  }
  87% {
    opacity: 0;
  }
  93% {
    opacity: 0;
  }
  94% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes defect {
  0% {
    opacity: 1;
  }
  0.9% {
    opacity: 1;
  }
  1% {
    opacity: 0;
  }
  1.9% {
    opacity: 0;
  }
  2% {
    opacity: 1;
  }
  3% {
    opacity: 1;
  }
  3.1% {
    opacity: 0;
  }
  5% {
    opacity: 0;
  }
  7% {
    opacity: 0;
  }
  7.1% {
    opacity: 1;
  }
  14% {
    opacity: 1;
  }
  34% {
    opacity: 1;
  }
  34.1% {
    opacity: 0;
  }
  65% {
    opacity: 0;
  }
  65.3% {
    opacity: 1;
  }
  65.9% {
    opacity: 0;
  }
  69.0% {
    opacity: 1;
  }
  71% {
    opacity: 1;
  }
  71.1% {
    opacity: 0;
  }
  72% {
    opacity: 0;
  }
  72.1% {
    opacity: 1;
  }
  77% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes slow-fade {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.menu {
    &__list {
        position: fixed;
        top: 0;
        left: 0;
        list-style-type: none;
        display: flex;
        flex-direction: column;
        margin: 0;
        padding: 0;
        justify-content: flex-start;
        align-items: flex-start;
    }

    &__item {
        display: block;
        font-family: consolas, "liberation mono", menlo, monospace;
        font-size: 13px;
        padding: 12px 12px;
        text-decoration: none;
        color: #38c;

        &:hover {
            background-color: fade(#38c, 15%);
        }
    }
}

#vue-menu {
    position: fixed;
    top: 0;
    left: 0;

    ul {
        list-style-type: none;
        display: flex;
        flex-direction: column;
        margin: 0;
        padding: 0;
        justify-content: flex-start;
        align-items: flex-start;

        li {
            display: block;

            a {
                display: block;
                font-family: consolas, "liberation mono", menlo, monospace;
                font-size: 13px;
                padding: 12px 12px;
                text-decoration: none;
                color: #38c;

                &:hover {
                    background-color: fade(#38c, 15%);
                }
            }
        }
    }
}
