








































































































.gradients-page {
    display: flex;
    font-family: sans-serif;
    padding: 3rem;
}

pre {
    font-size: 0.8rem;
    background-color: #f0f0f0;
    font-family: consolas, monospace;
    padding: 0.5rem;
}

.card {
    margin: 24px;
    width: 200px;
    height: 200px;
    border-radius: 10px;
    flex: 0 0 200px;

    // background: radial-gradient(ellipse at top left, rgba(255, 255, 0, 0.25), rgba(0, 255, 255, 0.5)), linear-gradient(45deg, #34589f, #349055);
}

.form {
    border: 1px solid #f0f0f0;
    margin-left: 4rem;
    padding: 1rem;

    h4:first-child {
        margin-top: 0;
    }
}

.color-input,
.angle-input,
.position-input {
    padding-left: 3rem;
    min-height: 32px;
    margin-bottom: 0.25rem;

    label {
        min-width: 80px;
        display: inline-block;
    }

    input[type=text] {
        padding: 0.5rem;
        border: 1px solid #ccc;
        border-radius: 2px;
    }
}

.color-input {
    position: relative;

    .swatch {
        width: 32px;
        height: 32px;
        position: absolute;
        top: 0;
        left: 0;
    }
}
