html {
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

html,
body {
    margin: 0;
    padding: 0;
    font-size: 100%;
}

body {
    font-size: 1rem;
    min-height: 100vh;
    display: flex;
}

.vue-app {
    flex: 1;
    display: flex;

    &__body {
        flex: 1;
    }
}