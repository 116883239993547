















































































































































































.vue-app__body {
    font-family: consolas, monospace;
    padding: 2rem;
    filter: contrast(0.2) sepia(1) saturate(1) hue-rotate(66deg);

    @import "../../css/fallout.less";
}
