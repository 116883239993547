


























































.ui-app {
    overflow: hidden;
}

.thumbnail {
    margin: -1rem;
    margin-right: 1rem;
    width: 30px;
    height: 30px;
    opacity: 0.5;
    border: 1px solid var(--dark);
    outline: 2px solid var(--dark);
    outline-offset: 2px;
    position: relative;
    top: -5px;
}

.ui-block:hover .thumbnail {
    z-index: 2;
}
