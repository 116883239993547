























































@import url("https://fonts.googleapis.com/css2?familiy=Roboto+Condensed&family=Merriweather:ital@1&display=swap");

.vue-page {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%2392aca7' fill-opacity='0.4' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E");
}

h1 {
    font-family: "Roboto Condensed", sans-serif;
}

.app-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1rem;
    width: 1200px;
}

.card {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    color: rgba(0, 0, 0, 0.95);
    border-radius: 0.5rem;
    background-color: #fff;
    box-shadow: 0 2px 15px #0001;

    &__thumbnail {
        display: block;
        flex: 0 0 180px;
        margin: 0 0 1rem;
        border-radius: 0.5rem 0.5rem 0 0;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transform: scale(1.0);
            transition: transform 1s cubic-bezier(0, 1, 0.5, 1);
        }
    }

    &__title {
        font-size: 1.25rem;
        font-family: "Roboto Condensed", sans-serif;
        line-height: 1.1;
        letter-spacing: 0.025rem;
        margin: 0 0 0.5rem;
        padding: 0 1.5rem;
    }

    &.card--no-image {
        .card__title {
            padding-top: 1.5rem;
            padding-bottom: 1em;
        }
    }

    &__description {
        font-size: 0.9rem;
        color: rgba(0, 0, 0, 0.8);
        font-family: Merriweather, serif;
        padding: 0 1.5rem 1.5rem;
    }

    &:hover {
        .card__thumbnail img {
            transform: scale(1.2);
        }
    }
}
