













































































@dark: #2d5933;
@light: #82e997;
@medium: mix(@dark, @light, 50%);

.vue-page {
    color: #2d5933;
    background-color: #82e997;
    font-family: "Open Sans", sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}

.description {
    color: @medium;

    p {
        margin: 0;
    }

    p + p {
        margin-top: 1rem;
    }

    a {
        color: @dark;
    }
}

.panel {
    max-width: 720px;
}

.seed-input {
    border: 2px solid #2d5933;
    display: flex;
    gap: 0;
    padding: 0;
    border-radius: 0.25rem;
    margin-top: 2rem;

    label,
    button,
    input {
        font-family: Cabin, sans-serif;
    }

    label, button {
        display: flex;
        align-items: center;
    }

    label {
        padding: 1rem 2rem;
    }

    button {
        background-color: @dark;
        color: @light;
        border: 0 none;
        padding: 0;
        flex-basis: 6rem;
        justify-content: center;
        font-weight: bold;
        text-transform: uppercase;

        &:hover {
            cursor: pointer;
            color: @medium;
        }
    }

    input {
        padding: 1rem;
        font-size: 2.5rem;
        border: 0 none;
        flex: 1;
        background-color: transparent;
        color: @dark;

        &:focus {
            outline: none;
            background-color: @medium;
        }
    }
}

.current {
    background-color: @dark;
    font-size: 2rem;
    color: @light;
    display: flex;
    padding: 3rem;
    margin-top: 2rem;
    border-radius: 0.25rem;
    justify-content: center;
    align-items: center;
}
