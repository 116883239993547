

































































































































































.vue-page,
.dropzone {
    min-height: 100vh;
}

.dropzone {
    background-color: #eee;
    color: #333;
    padding: 2rem;
    font-family: sans-serif;
}

.dropzone-notice {
    position: fixed;
    pointer-events: none;
    background-color: rgba(175, 200, 220, 0.25);
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    > div {
        color: #fff;
        border: 0.1em dashed #fff;
        border-radius: 0.5em;
        padding: 3rem;
        font-size: 4rem;
    }
}

canvas {
    box-shadow: 0 5px 25px rgba(0, 0, 0, 0.1);
}

.row {
    display: flex;

    .col {
        flex: 1;
        display: flex;
        flex-direction: column;
    }

    .col--form {
        flex: 0 0 12rem;
        margin-right: 2rem;
    }
}

.form-group {
    margin-bottom: 1rem;

    label {
        display: block;
        opacity: 0.5;
        font-weight: bold;
        font-size: 0.8rem;
        text-transform: uppercase;
        margin-bottom: 0.15rem;
    }

    input {
        display: block;
        width: 100%;
        font-size: 1.2rem;
        padding: 0.25rem;
        font-family: sans-serif;
        color: rgba(0, 0, 0, 0.9);
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 3px;
    }
}

.result {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 1rem;
}
