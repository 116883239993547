






















































































.color-pair {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: Cabin, "Comic Sans MS", monospace;
    position: relative;

    &__color {
        font-size: min(2rem, 2.5vw);
        font-weight: bold;
        text-transform: uppercase;
    }

    &__button {
        border: 0 none;
        text-transform: uppercase;
        padding: 0.5rem 1rem;
        border-radius: 0.125rem;
        display: inline-flex;
        margin-top: 1rem;
        cursor: pointer;
        line-height: 1;
        justify-content: center;
        align-items: center;

        svg {
            fill: currentColor;
            width: 1rem;
            height: 1rem;
        }
    }

    &__save {
        display: none;
        position: absolute;
        bottom: 2rem;
        left: 2rem;
        border: 1px solid transparent;
        background-color: transparent;
        border-radius: 999px;
        width: 2rem;
        height: 2rem;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &:hover {
            border: 1px solid currentColor;
        }

        svg {
            fill: currentColor;
            width: 1rem;
            position: relative;
            top: 1px;
        }
    }

    &:hover &__save {
        display: flex;
    }
}
