














































































.color-pair-grid {
    display: grid;
    // grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    // grid-auto-rows: 280px;
    grid-template-columns: repeat(5, 20vw);
    grid-auto-rows: 20vh;
}

.options {
    position: fixed;
    top: 0;
    left: 0;
    padding: 1rem;
    font-family: Cabin, "Comic Sans MS", monospace;
    background-color: #fff;
    color: #000;
}
