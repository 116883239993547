






















































.vue-page {
    min-height: 100vh;
    background-color: #f2f2f2;
    padding: 2rem;
    font-family: sans-serif;
}

h1 {
    font-size: 4rem;
    margin: 0 0 0.25em 0;
}

label {
    text-transform: uppercase;
    letter-spacing: 0.01em;
    color: #333;
}

textarea {
    border: 1px solid orange;
    padding: 0.25em;
    display: block;
    width: 100%;
    font-size: 1.2rem;
    border-radius: 2px;
}

.form-actions {
    margin-top: 1rem;

    button {}
}
