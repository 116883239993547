








































































































































































































































































































































































@import (less) "../../fonts/cabin/cabin.css";

.vue-page {
    background-color: #fff;
    color: #000;
    font-family: Cabin, sans-serif;
    font-size: 100%;
    min-height: 100vh;
    font-size: 22px;
}

.page {
    min-height: 100vh;
    width: 48rem;
    background-color: #fff;
    margin: 0 auto;
    padding: 1.75rem;
    line-height: 1.5;
}

.toolbar {
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
}

.toolbar-group {
    display: flex;
    gap: 0.5rem;
}

.page-title {
    margin-bottom: 1rem;

    h1 {
        margin: 0;
        padding: 0;
        font-size: 2rem;
        font-weight: bold;

        span {
            cursor: pointer;
            position: relative;
            top: -10px;
            padding: 1px 6px;
            background-color: #000;
            color: #fff;
            font-weight: bold;
            font-size: 0.75rem;
        }
    }
}

.help {
    border: 3px solid black;
    border-left-style: none;
    border-right-style: none;

    p {
        margin-top: 0;
    }
}

h1, h2 {
    font-size: 1rem;
    text-transform: uppercase;
}

h1 {
    font-weight: bold;
}

h2 {
    font-weight: normal;
    text-decoration: underline;
}

span {
    // background-color: #000;
}

table {
    width: 100%;

    thead {
        th {
            border-bottom: 4px solid #000;
            border-top: 4px solid #000;
            font-weight: bold;
            font-style: italic;
            text-align: left;
        }
    }

    th,
    td {
        padding: 0.5rem 0;
    }
}

.game {
    position: relative;

    &:after {
        content: "";
        position: absolute;
        top: 1.5rem;
        left: -1.2rem;
        height: 2px;
        width: 1rem;
        background-color: black;
    }
}

.game-index {
    position: absolute;
    top: 13px;
    left: -53px;
    font-size: 0.45rem;
    font-weight: bold;
    letter-spacing: 0.07em;
    text-align: right;
    width: 50px;
}

.game-name {
    font-weight: bold;

    a {
        text-decoration: none;
        color: #000;
    }
}

.game-info {
    font-size: 0.75rem;
}

.has-platinum {
    &::before {
        content: "";
        display: inline-block;
        width: 1em;
        height: 1em;
        border: 2px solid black;
        position: relative;
        top: 1px;
    }

    &.has-platinum--1 {
        &::before {
            background-color: #000;
        }
    }
}

.appeal--out-of,
.platinum-difficulty--out-of {
    opacity: 0.25;
}

.appeal-index {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0.25rem 0.75rem;
    background-color: #000;
    color: #fff;
    font-weight: bold;
    font-size: 1.1rem;
}

.sortable {
    span {
        position: relative;
        cursor: pointer;
    }

    span::after {
        position: absolute;
        left: calc(100% + 0.25rem);
        color: #000;
        font-size: 0.75rem;
        top: 5px;
    }

    &.active {
        &.desc {
            span::after { content: "▼"; }
        }

        &.asc {
            span::after { content: "▲"; }
        }
    }
}

.filter-input {
    display: inline-flex;
    position: relative;

    input[type=text] {
        background-color: #000;
        color: #fff;
        text-transform: uppercase;
        padding: 0.5rem;
        font-weight: bold;
        min-width: 20rem;
        border: 0;
    }

    button {
        position: absolute;
        right: 0;
        top: 0;
        width: 40px;
        height: 100%;
        border: 0;
        background-color: #000;
        color: #fff;
        font-weight: bold;
        cursor: pointer;

        &:hover {
            color: yellow;
        }
    }
}

.toolbar {
    display: flex;
    gap: 1rem;
}

.checkbox {
    position: relative;

    input {
        position: absolute;
        opacity: 0;
    }

    input + label {
        cursor: pointer;

        &:before {
            content: "";
            display: inline-block;
            width: 1rem;
            height: 1rem;
            border: 1px solid black;
            background-color: transparent;
            transition: background-color 1.5s cubic-bezier(0, 1, 0.5, 1);
            margin-right: 0.5rem;
        }
    }

    input:checked + label {
        &:before {
            background-color: black;
        }
    }
}

.screen {
    position: fixed;
    background-color: #fffa;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    z-index: 1020;

    .modal {
        background-color: #fff;
        padding: 3rem;
        border: 4px solid #000;

        .form-group {
            margin-bottom: 1rem;
        }

        .form-actions {
            margin-bottom: 0;
            display: flex;
            gap: 1.5rem;
            justify-content: flex-end;
            align-items: center;
        }

        input[type=text] {
            background-color: #000;
            color: #fff;
            padding: 0.5rem;
            font-weight: bold;
            min-width: 20rem;
            border: 0;
        }

        a {
            color: #000;
            opacity: 0.5;
            text-decoration: none;
            text-transform: uppercase;
            font-size: 0.8rem;
            font-weight: bold;

            &:hover {
                opacity: 0.7;
            }
        }
    }
}

button {
    border: 2px solid #000;
    cursor: pointer;
    padding: 0.5rem 1rem;
    background-color: #fff;
    text-transform: uppercase;
    font-weight: bold;
}
