.title {
    padding: 1rem;
    border: 1px dashed black;
    margin-bottom: 1.5rem;
}

.menu {
    margin: 1rem 0;
    padding: 0;
    display: flex;
    gap: 0.5rem;
    border-bottom: 1px dashed black;

    * + * {
        border-left: 1px dashed black !important;
    }

    a, button {
        font-family: consolas, monospace;
        font-weight: normal;
        font-size: 1rem;
        text-decoration: underline;
        color: darkcyan;
        border: 0 none;
        padding: 0.5rem 1rem 1rem;
        background-color: transparent;
        cursor: pointer;
    }
}

.wordlists {
    display: flex;
    align-items: flex-start;
}

.wordlist {
    display: flex;
    flex-direction: column;
    position: relative;

    label {
        position: absolute;
        left: 0.5rem;
        top: 0.2rem;
        opacity: 0.25;
        pointer-events: none;
    }

    .input-matches {
        margin-bottom: 0.5rem;
        border: 1px dashed black;
        border-left-width: 0;
        font-family: consolas, monospace;
        padding: 0.25rem;
        text-align: right;

        &&--0 {
            border-left-width: 1px;
        }
    }
}

.word {
    padding: 0.5rem 1rem;
    cursor: pointer;

    &.inactive {
        cursor: default;
        opacity: 0.4;

        &:hover {
            background-color: white;
        }
    }

    &:hover {
        background-color: #808080;
    }

    &.word--picked {
        background-color: black;
        color: white;
    }

    &.word--correct {
        background-color: yellow !important;
        color: black !important;
    }
}

.matches {
    margin-left: 2rem;
    border: 1px dashed black;
    padding: 1.5rem;

    .value {
        text-align: right;
    }
}

.enter-popup {
    position: fixed;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.25);

    &__form {
        display: flex;
        flex-direction: column;
        background-color: #fff;
        gap: 1rem;
        padding: 2rem;
        border: 1px dashed black;
    }

    &__textarea {
        border: 1px dashed black;
        min-height: 420px;
    }

    &__actions {
        text-align: right;
    }

    button {
        border: 0 none;
        font-family: consolas, monospace;
        background-color: transparent;
        cursor: pointer;
        text-decoration: underline;
        font-size: 1rem;
    }
}
