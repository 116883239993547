@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@100;200;300;400;500;600;700;800&display=swap');

@font_family: Heebo, sans-serif;

@light: #d9d1ba;
@dark: #464237;
@primary: #cd664d;

:root {
    --bg: mix(@light, @dark, 90%);
    --text: @dark;
    --font-family: @font_family;

    --primary: @primary;

    --light: @light;
    --light-10: fade(@light, 10%);
    --light-20: fade(@light, 20%);
    --light-25: fade(@light, 25%);
    --light-30: fade(@light, 30%);
    --light-40: fade(@light, 40%);
    --light-50: fade(@light, 50%);
    --light-60: fade(@light, 60%);
    --light-70: fade(@light, 70%);
    --light-75: fade(@light, 75%);
    --light-80: fade(@light, 80%);
    --light-90: fade(@light, 90%);
    --light-100: fade(@light, 100%);

    --dark: @dark;
    --dark-10: fade(@dark, 10%);
    --dark-20: fade(@dark, 20%);
    --dark-25: fade(@dark, 25%);
    --dark-30: fade(@dark, 30%);
    --dark-40: fade(@dark, 40%);
    --dark-50: fade(@dark, 50%);
    --dark-60: fade(@dark, 60%);
    --dark-70: fade(@dark, 70%);
    --dark-75: fade(@dark, 75%);
    --dark-80: fade(@dark, 80%);
    --dark-90: fade(@dark, 90%);
    --dark-100: fade(@dark, 100%);
}

.ui-app {
    background-color: var(--bg);
    color: var(--text);
    font-family: var(--font-family);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    font-size: 100%;
    line-height: 1;
    overflow-y: scroll;
    position: relative;
    margin: 0;
    padding: 0;
    min-height: 100vh;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote {
        font-weight: normal;
        line-height: 1;
        margin: 0;
    }

    &::before {
        content: "";
        z-index: 999999;
        background: transparent;
        box-shadow: inset 0 0 20vw black;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0.5;
        pointer-events: none;
        position: fixed;
    }

    &::after {
        content: "";
        z-index: 999999;
        background: var(--bg) url(./bg.png) center repeat;
        background-size: 4px 4px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        filter: blur(1px);
        opacity: 0.1;
        pointer-events: none;
    }
}

.ui-container {
    margin: 0 auto;
    width: 100%;
    max-width: 40rem;
}

.ui-page-title {
    margin: 4rem 0;
    text-align: center;

    &__title {
        font-size: 3rem;
        text-shadow: 4px 4px 0 var(--dark-20);
        font-weight: normal;
        margin: 0;
        padding: 0;
    }
}

.ui-navbar {
    display: flex;
    align-items: center;
    gap: 3rem;

    &__brand {
        color: var(--dark-80);
        text-decoration: none;
        font-weight: bold;
        text-transform: uppercase;

        &:hover {
            color: var(--dark);
        }
    }

    &__menu {
        margin: 0;
        padding: 0;
        display: flex;
        gap: 3rem;
    }

    &__menu-item {
        background-color: var(--dark-75);
        color: var(--light);
        text-decoration: none;
        padding: 0.5rem 1rem;
        text-transform: uppercase;
        display: flex;
        align-items: center;

        .icon {
            display: flex;
            margin-right: 1rem;
        }

        &:hover {
            background-color: var(--dark);
            color: var(--light);
        }
    }
}

.mar-t-3 { margin-top: 3rem; }
.mar-b-3 { margin-bottom: 3rem; }
.mar-l-3 { margin-left: 3rem; }
.mar-r-3 { margin-right: 3rem; }
.pad-b-1 { padding-bottom: 1rem; }
.max-w-40 { max-width: 40rem; }
.block-center { margin-left: auto; margin-right: auto; }
.pos-r { position: relative; }

.ui-panel {
    background-color: var(--light);
    box-shadow: 4px 4px 0 var(--dark-20);

    .block-center();
}

.ui-list {
    // .ui-panel;

    margin-top: 1rem;
    margin-bottom: 1rem;
    padding: 0 0 1rem 0;
    position: relative;

    ul {
        margin: 0;
        padding: 0;
    }
}

.ui-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: 16px 32px;
    transition: all 0.25s cubic-bezier(0, 1, 0.5, 1);
    color: var(--dark);
    text-decoration: none;
    letter-spacing: 0.02em;

    + .ui-block {
        border-top: 1px solid var(--dark-10);
    }

    &__name {
        text-decoration: none;
        color: var(--dark);
    }

    &__tags {
        display: flex;
        gap: 0.25em;
    }

    &::before,
    &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: var(--dark);
        opacity: 0;
        left: 0;
        transition: all 0.5s cubic-bezier(0, 1, 0.5, 1);
    }

    &::before {top: 1px;}
    &::after {bottom: 1px;}

    &:hover {
        background-color: var(--dark);
        color: var(--light);

        &::before,
        &::after {
            content: "";
            opacity: 1;
        }

        &::before {transform: translateY(-8px);}
        &::after {transform: translateY(8px);}

        .ui-block__name {
            color: var(--light);
        }

        .ui-tag {
            color: var(--light-80);
            border-color: var(--light-60);
        }
    }
}

.ui-filter {
    margin: 0 0 1.5rem 0;
    display: flex;
    justify-content: center;
    align-items: stretch;
    box-shadow: 0 4px 0 var(--dark-20);
    position: relative;

    &__label,
    &__result {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 0 1rem;
    }

    &__label {
        display: none;
    }

    &__result {
        opacity: .75;
        position: absolute;
        bottom: calc(100% + 0.25rem);
        right: -1rem;
        font-size: 0.6rem;
        text-transform: uppercase;
    }

    &__input {
        background-color: var(--dark-50);
        border: 0 none;
        color: var(--dark);
        margin: 0;
        padding: 2px 8px;
        flex: 1;
        text-transform: uppercase;
    }

    &__clear-button {
        margin: 0;
        padding: 0;
        border: 0;
        background-color: var(--dark);
        color: var(--light);
        flex-basis: 32px;
        height: 32px;
        font-weight: bold;
        cursor: pointer;
        transition: color 1s ease-in-out;

        &:disabled {
            color: var(--dark-40);
            pointer-events: none;
        }
    }
}

.ui-sidebar {
    position: absolute;
    left: calc(100% + 2rem);
    top: 0;
    height: 100%;

    &__content {
        display: flex;
        flex-direction: column;
        background-color: var(--light);
        box-shadow: 4px 4px 0 var(--dark-20);
    }

    &--sticky {
        .ui-sidebar__content {
            position: sticky;
            top: 0;
        }
    }

    &_item {
        &--padded {
            padding: 0.5rem 0.75rem;
        }

        .ui-tag {
            cursor: pointer;

            &:hover {
                border-color: var(--primary);
            }
        }
    }
}

.ui-pagination {
    display: flex;
    justify-content: space-between;
    background-color: var(--dark);
    margin: 3rem auto;
    padding: 0;
    max-width: 40rem;
    box-shadow: 4px 4px 0 var(--dark-20);

    &__button {
        flex: 1;
        height: 4rem;
        margin: 0;
        padding: 0;
        display: block;
        outline: 0 none;
        border: 0 none;
        background-color: transparent;
        height: 4rem;
        color: var(--light-80);
        cursor: pointer;
        text-transform: uppercase;
        font-weight: bold;
        letter-spacing: 0.15em;

        &:hover {
            color: var(--primary);
        }

        &[disabled] {
            color: var(--light-25);
            cursor: default;
        }

        &.ui-pagination__button--current {
            background-color: var(--light-20);
        }
    }
}

.ui-form {
    background-color: var(--light);
    margin: 3rem auto;
    padding: 0 0 0;
    max-width: 40rem;
    width: 100%;
    box-shadow: 4px 4px 0 var(--dark-20);

    &__group {
        & + & {
            margin-top: 2rem;
        }

        &--actions {
            display: flex;
            gap: 0.25rem;
            justify-content: flex-end;
        }
    }
}

.ui-input {
    background-color: var(--dark-50);
    border: 0 none;
    color: var(--dark);
    margin: 0;
    padding: 2px 8px;
    flex: 1;
    width: 100%;
    display: block;
    min-height: 32px;
    font-size: 1rem;

    &--text {

    }
}

.ui-radiogroup {
    display: flex;
    flex-direction: column;
    position: relative;

    input[type=radio] {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
    }

    label {
        background-color: var(--dark-50);
        padding: 0.5rem;
        margin-bottom: 0.1rem;
        cursor: pointer;
        transition: background-color 0.25s ease-in-out;

        &:hover {
            background-color: var(--dark-40);
        }
    }

    input:checked + label {
        background-color: var(--dark);
        color: var(--light);


        &:hover {
            background-color: var(--dark);
        }
    }
}

.ui-form-label {
    display: inline-block;
    text-transform: uppercase;
    font-size: 0.8rem;
    color: var(--dark-80);
    padding: 0.5rem;

    &--radio {

    }
}

.ui-button {
    display: inline-flex;
    background-color: var(--dark-80);
    color: var(--light-80);
    border: 0 none;
    border-radius: 0;
    padding: 12px 24px;
    text-transform: uppercase;
    cursor: pointer;
    font-size: 0.8rem;
    text-decoration: none;
    line-height: 1;
    justify-content: center;
    align-items: center;

    &:hover {
        background-color: var(--dark);
    }

    &--disabled,
    &:disabled {
        color: var(--dark-40);
        pointer-events: none;
    }

    &--danger {
        color: var(--primary);

        &:hover {
            color: #fff;
            background-color: var(--primary);
        }
    }
}

.ui-button--text {
    color: var(--dark);
    background-color: var(--dark-20);
    display: inline-flex;
    padding: 2px 4px;
    font-size: 0.6rem;
    margin: 0.1rem;
    text-decoration: none;

    &:hover {
        background-color: var(--primary);
        color: var(--light);
    }
}

.ui-tag {
    font-size: 0.7rem;
    text-transform: uppercase;
    display: inline-flex;
    color: var(--dark-80);
    border: 1px solid var(--dark-60);
    padding: 2px 3px 1px;
    transition: all 0.25s cubic-bezier(0, 1, 0.5, 1);
    white-space: nowrap;

    &--active {
        background-color: var(--dark);
        color: var(--light);
    }
}

.ui-filter-list {
    padding: 0.5rem;

    &__item {
        display: flex;
        align-items: center;
        padding: 0.5rem;
        cursor: pointer;

        &-check {
            display: inline-block;
            width: 1rem;
            height: 1rem;
            margin-right: 0.5rem;
            background-color: var(--dark);
            opacity: 0.8;
            flex: 0 0 1rem;
        }

        &--active {
            .ui-filter-list__item-check {
                background-color: var(--primary);
            }
        }

        &-label {
            font-size: 0.7rem;
            text-transform: uppercase;
            display: inline-flex;
            color: var(--dark);
            opacity: 0.8;
            white-space: nowrap;
        }

        &:hover {
            .ui-filter-list__item-check,
            .ui-filter-list__item-label {
                opacity: 1;
            }
        }
    }
}
